import { defineStore } from 'pinia';
import { IAuthToken, IAuthUser } from './auth.interface';
import { eAuth } from './auth.endpoints';
import { useCookies } from '@vueuse/integrations/useCookies';

export const useAuthStore = defineStore('auth', () => {
  const user = ref<IAuthUser | undefined>(undefined);
  const initilized = ref(false);
  const { $router } = useNuxtApp();

  async function init() {
    try {
      await getUser();
    } finally {
      initilized.value = true;
    }
  }

  async function getUser(refresh = false) {
    if (!refresh && user.value) return user.value;
    const accessToken = useCookies().get<string | undefined>(ACCESS_TOKEN);
    if (!accessToken) return;

    user.value = await useApi<IAuthUser>({
      ...eAuth.getUser,
      key: 'get-profile',
    });
    return user.value;
  }
  async function login(email: string, password: string) {
    try {
      const data = await useApi<IAuthToken>({
        ...eAuth.login,
        body: { email, password },
      });
      setTokens(data);
      await getUser(true);
    } catch (error) {
      setTokens();
    }
  }
  async function logout() {
    useApi(eAuth.logout);
    setTokens();
  }
  function register(name: string, email: string, password: string) {}
  function refresh() {}
  function setTokens(tokens?: IAuthToken) {
    const $cookies = useCookies();
    if (!tokens) {
      $cookies.remove(ACCESS_TOKEN);
      $cookies.remove(REFRESH_TOKEN);
      user.value = undefined;
    } else {
      $cookies.set(ACCESS_TOKEN, tokens.token);
      $cookies.set(REFRESH_TOKEN, tokens.refreshToken);
    }
  }

  watch(
    user,
    (v, o) => {
      if (initilized.value) {
        const redirect = $router.currentRoute.value.query.redirect as
          | string
          | undefined;
        if (o && !v) {
          $router.push({ path: '/login', query: { redirect } });
        }
        if (v && !o) {
          $router.push({ path: redirect ?? '/' });
        }
      }
    },
    { deep: true, immediate: true }
  );

  init();

  return {
    user,
    getUser,
    login,
    logout,
    register,
  };
});
