import { useCookies } from '@vueuse/integrations/useCookies';
import { WatchSource } from 'nuxt/dist/app/compat/capi';
import { ACCESS_TOKEN } from '~/utils/constants';

const useApiRequestMap = ref(new Map<string, Promise<any>>());

export const useApi = <T = any>(req: IRequest, opt?: object): Promise<T> => {
  if (opt && isParsableString(req.url)) {
    req.url = parseString(req.url, opt);
  }

  if (req.key) {
    const k = req.key;
    const s = useApiRequestMap.value.get(req.key);
    if (s) {
      return s;
    }
    const r = createRequest<T>(req);
    if (req.cacheResponse) {
      r.then((res) => {
        useApiRequestMap.value.set(k, Promise.resolve(res));
      });
    } else {
      r.finally(() => {
        useApiRequestMap.value.delete(k);
      });
    }
    useApiRequestMap.value.set(req.key, r);

    return r;
  }

  return createRequest(req);
};

const createRequest = <T = any>(req: IRequest): Promise<T> => {
  const accessToken = useCookies().get<string | undefined>(ACCESS_TOKEN);

  return $fetch(req.url, {
    ...req,

    onRequest: (ctx) => {
      const headers = ctx.options?.headers
        ? new Headers(ctx.options.headers)
        : new Headers();

      if (accessToken && !req.skipAuth) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      ctx.options.headers = headers;
    },
  });
};

type RequestMethodType =
  | 'GET'
  | 'HEAD'
  | 'PATCH'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'get'
  | 'head'
  | 'patch'
  | 'post'
  | 'put'
  | 'delete'
  | 'connect'
  | 'options'
  | 'trace';

export interface IRequest {
  url: string;
  key?: string;
  cacheResponse?: boolean;
  method?: RequestMethodType;
  query?: Map<string, any>;
  body?: RequestInit['body'] | Record<string, any>;
  headers?: Record<string, string> | [key: string, value: string][] | Headers;
  server?: boolean;
  transform?: (input: any) => any;
  watch?: WatchSource[];
  immediate?: boolean;
  skipAuth?: boolean;
  pick?: any;
  signal?: AbortSignal;
}

export type IEndpoints = typeof AssertRecordType<IRequest>;

export const AssertRecordType =
  <T>() =>
  <D extends Record<string, T>>(d: D) =>
    d;

export const createEndpoints = <T extends Record<string, IRequest>>(d: T) => d;
