import { createEndpoints } from '~/composables/useApi';

export const eAuth = createEndpoints({
  login: {
    method: 'post',
    url: '/auth/persons/login',
  },
  getUser: {
    method: 'get',
    url: '/auth/persons/profile',
  },
  logout: {
    method: 'post',
    url: '/auth/persons/logout',
  },
});
